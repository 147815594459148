import React from "react";

function Footer() {
  return (
    <footer>
      <div>
        <p>Copyright Beauty Kim</p>
      </div>
    </footer>
  );
}

export default Footer;
